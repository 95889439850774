import React from "react";
import { Alert, Box, Button, Grid, TextField } from "@mui/material";
import { Athlete } from "../services/athletes";
import AthleteCard from "./AthleteCard";
import { AthletePrice } from "../utils/getAthletePrice";
import AffiliateSearch from "../components/affiliates/AffiliateSearch";
import { Affiliate } from "../components/affiliates/useLoadAffiliates";
import { StyledHeader } from "../components/styledText";

interface MyTeamProps {
  selectedAthletes: Athlete[];
  selectedAffiliate: Affiliate | null;
  displayName: string;
  authUid: string | null;
  onToggleAthleteSelection: (athlete: Athlete) => void;
  onSaveTeam: () => void;
  onAffiliateChange: (affiliate: Affiliate | null) => void;
  onDisplayNameChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  prices: AthletePrice[];
  affiliates: Affiliate[];
  errorMessage: string | null;
}

const MyTeam: React.FC<MyTeamProps> = ({
  selectedAthletes,
  selectedAffiliate,
  displayName,
  authUid,
  onToggleAthleteSelection,
  onSaveTeam,
  onAffiliateChange,
  onDisplayNameChange,
  prices,
  affiliates,
  errorMessage,
}) => {
  const getPrice = (athleteId: string) => {
    return prices.find((price) => price.competitorId === athleteId);
  };

  return (
    <Box mb={3} style={{ display: "flex", flexDirection: "column" }}>
      <AffiliateSearch
        affiliates={affiliates}
        onSelectAffiliate={onAffiliateChange}
        selectedAffiliate={selectedAffiliate}
        affiliateLabel={"My affiliate"}
      />
      <TextField
        label="Display Name"
        variant="outlined"
        value={displayName}
        onChange={onDisplayNameChange}
        style={{ marginTop: "20px", marginBottom: "20px", maxWidth: "300px" }}
      />
      <StyledHeader
        variant={"h5"}
        style={{ marginTop: "20px", marginBottom: "20px" }}
      >
        {" "}
        My Athletes{" "}
      </StyledHeader>
      <Grid container spacing={2}>
        {selectedAthletes.map((athlete) => (
          <Grid item xs={12} sm={6} md={4} key={athlete.id}>
            <AthleteCard
              athlete={athlete}
              price={getPrice(athlete.id)?.price || 0}
              isSelected={!!selectedAthletes.find((a) => a.id === athlete.id)}
              toggleAthleteSelection={onToggleAthleteSelection}
              isRookie={getPrice(athlete.id)?.isRookie ?? false}
            />
          </Grid>
        ))}
      </Grid>
      {errorMessage && (
        <Alert
          severity="error"
          style={{ marginTop: "20px", width: "fit-content" }}
        >
          {errorMessage}
        </Alert>
      )}
      <Button
        variant="contained"
        onClick={onSaveTeam}
        disabled={!authUid}
        style={{ marginTop: "20px", width: "fit-content" }}
      >
        Save Team
      </Button>
    </Box>
  );
};

export default MyTeam;
