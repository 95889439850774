import React from "react";
import { IconButton, Snackbar, SnackbarContent } from "@mui/material";
import { green } from "@mui/material/colors";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import theme from "../theme";
import CloseIcon from "@mui/icons-material/Close";

interface SuccessSnackbarProps {
  message: string;
  snackbarOpen: boolean;
  handleSnackbarClose: (
    event: React.SyntheticEvent | Event,
    reason?: string,
  ) => void;
}

const Success = ({
  message,
  snackbarOpen,
  handleSnackbarClose,
}: SuccessSnackbarProps) => {
  return (
    <>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <SnackbarContent
          style={{ backgroundColor: green[600] }}
          message={
            <span style={{ display: "flex", alignItems: "center" }}>
              <CheckCircleIcon style={{ marginRight: theme.spacing(1) }} />
              {message}
            </span>
          }
          action={[
            <IconButton
              key="close"
              aria-label="close"
              color="inherit"
              onClick={handleSnackbarClose}
            >
              <CloseIcon />
            </IconButton>,
          ]}
        />
      </Snackbar>
    </>
  );
};

export default Success;
