import React, { useState } from "react";
import { db } from "../services/firebase"; // Assuming you've set up Firebase
import { collection, addDoc } from "firebase/firestore";
import { useRecoilValue } from "recoil";
import { authState } from "../state/authState";
import {
  TextField,
  Button,
  Typography,
  IconButton,
  SnackbarContent,
  Snackbar,
} from "@mui/material";
import styled from "styled-components";
import theme from "../theme";
import { green } from "@mui/material/colors";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";
import { StyledText } from "../components/styledText";

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 300px;
`;

const CreateLeague = () => {
  const auth = useRecoilValue(authState);
  const [leagueName, setLeagueName] = useState("");
  const [inviteCode, setInviteCode] = useState("");
  const [error, setError] = useState<string | null>(null);

  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleCreateLeague = async () => {
    if (!leagueName) {
      setError("League name is required");
      return;
    }

    try {
      const leaguesRef = collection(db, "leagues");
      const newLeague = {
        name: leagueName,
        createdBy: auth.uid,
        members: [auth.uid],
        inviteCode: Math.random().toString(36).substring(2, 8), // Simple invite code
      };

      await addDoc(leaguesRef, newLeague);
      setLeagueName("");
      setInviteCode(newLeague.inviteCode);
      setSnackbarOpen(true);
      setError(null);
    } catch (error) {
      setError("Error creating league");
      console.error("Error creating league:", error);
    }
  };

  return (
    <>
      {error && <Typography color="error">{error}</Typography>}
      <FlexContainer>
        <TextField
          label="League Name"
          variant="outlined"
          margin="normal"
          value={leagueName}
          onChange={(e) => setLeagueName(e.target.value)}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleCreateLeague}
          style={{ marginBottom: "0" }}
        >
          Create League
        </Button>
        {inviteCode && (
          <>
            <Snackbar
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              open={snackbarOpen}
              autoHideDuration={6000}
              onClose={handleSnackbarClose}
            >
              <SnackbarContent
                style={{ backgroundColor: green[600] }}
                message={
                  <span
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <CheckCircleIcon
                      style={{ marginRight: theme.spacing(1) }}
                    />
                    {`Your league invite code: ${inviteCode}`}
                  </span>
                }
                action={[
                  <IconButton
                    key="close"
                    aria-label="close"
                    color="inherit"
                    onClick={handleSnackbarClose}
                  >
                    <CloseIcon />
                  </IconButton>,
                ]}
              />
            </Snackbar>
            <StyledText style={{ marginTop: "20px" }}>
              Your league invite code: {inviteCode}
            </StyledText>
          </>
        )}
      </FlexContainer>
    </>
  );
};

export default CreateLeague;
