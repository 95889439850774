import React, { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../services/firebase";
import {
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { eventResultsState } from "../state/atheleteState";
import { calculateFinalScoresWithPrices } from "../utils/calculateScores";
import { baseScoresState } from "../state/scoringState";
import { useTheme } from "@mui/system";
import styled from "styled-components";
import AffiliateSearch from "../components/affiliates/AffiliateSearch";
import {
  Affiliate,
  useLoadAffiliates,
} from "../components/affiliates/useLoadAffiliates";
import { authState } from "../state/authState";
import { teamsState } from "../state/teamState";
import { leaguesState } from "../state/leagueState";
import { League } from "../LeagueView/MyLeagues";

export interface TeamData {
  userId: string;
  athletes: string[];
  affiliate: Affiliate | null;
  displayName: string;
}

const StyledTableRow = styled(TableRow)`
  &:nth-of-type(odd) {
    background-color: #f2f2f2; // Light grey color for odd rows
  }
  &:nth-of-type(even) {
    background-color: #ffffff; // White color for even rows
  }
`;

const Leaderboard: React.FC = () => {
  const [teams, setTeams] = useRecoilState(teamsState);
  const [leagues, setLeagues] = useRecoilState(leaguesState);
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedAffiliate, setSelectedAffiliate] = useState<Affiliate | null>(
    null,
  );
  const [selectedLeague, setSelectedLeague] = useState<string | null>(null);
  const [leaderboardData, setLeaderboardData] = useState<any[]>([]);
  const auth = useRecoilValue(authState);
  const eventResults = useRecoilValue(eventResultsState);
  const baseScores = useRecoilValue(baseScoresState);
  const theme = useTheme();
  const affiliates = useLoadAffiliates();

  useEffect(() => {
    const fetchTeams = async () => {
      setLoading(true);
      const q = query(collection(db, "teams"));
      const querySnapshot = await getDocs(q);
      const teamData = querySnapshot.docs.map((doc) => doc.data() as TeamData);
      setTeams(teamData);
      setLoading(false);
    };

    const fetchLeagues = async () => {
      if (!auth.uid) return;

      const leaguesRef = collection(db, "leagues");
      const q = query(leaguesRef, where("members", "array-contains", auth.uid));
      const querySnapshot = await getDocs(q);
      const leaguesData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      })) as League[];

      setLeagues(leaguesData);
    };

    fetchTeams();
    fetchLeagues();
  }, [auth.uid, setLeagues, setTeams]);

  useEffect(() => {
    if (!loading) {
      const leagueMembers = selectedLeague
        ? leagues.find((league) => league.id === selectedLeague)?.members || []
        : null;
      const scores = calculateFinalScoresWithPrices(
        teams,
        selectedAffiliate?.properties?.name ?? "",
        eventResults,
        baseScores,
        leagueMembers,
      );
      setLeaderboardData(scores);
    }
  }, [
    teams,
    loading,
    selectedAffiliate,
    eventResults,
    baseScores,
    selectedLeague,
    leagues,
  ]);

  const handleAffiliateChange = (affiliate: Affiliate | null) => {
    setSelectedAffiliate(affiliate);
  };

  const handleLeagueChange = (event: SelectChangeEvent) => {
    setSelectedLeague(event.target.value as string);
  };

  return (
    <>
      <div
        style={{ margin: "40px 0", display: "flex", flexDirection: "column" }}
      >
        <AffiliateSearch
          affiliates={affiliates}
          onSelectAffiliate={handleAffiliateChange}
          selectedAffiliate={selectedAffiliate}
          affiliateLabel={"Filter affiliate"}
        />
        <FormControl fullWidth style={{ width: "300px", marginTop: "20px" }}>
          <InputLabel id="league-select-label">Filter league</InputLabel>
          <Select
            labelId="league-select-label"
            id="league-select"
            label="Filter league"
            value={selectedLeague ?? undefined}
            onChange={handleLeagueChange}
          >
            <MenuItem value="">
              <em>Global</em>
            </MenuItem>
            {leagues.map((league) => (
              <MenuItem key={league.id} value={league.id}>
                {league.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      {loading ? (
        <CircularProgress />
      ) : (
        <Table>
          <TableHead>
            <TableRow style={{ backgroundColor: theme.palette.primary.main }}>
              <TableCell
                style={{
                  color: theme.palette.common.white,
                  fontWeight: "bold",
                }}
              >
                Name
              </TableCell>
              <TableCell
                style={{
                  color: theme.palette.common.white,
                  fontWeight: "bold",
                }}
              >
                Affiliate
              </TableCell>
              <TableCell
                style={{
                  color: theme.palette.common.white,
                  fontWeight: "bold",
                }}
              >
                Score
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {leaderboardData.map((data, index) => (
              <StyledTableRow key={index}>
                <TableCell>{data.displayName}</TableCell>
                <TableCell>
                  {data.affiliate ? data.affiliate.properties.name : ""}
                </TableCell>
                <TableCell>{data.score.toFixed(0)}</TableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </>
  );
};

export default Leaderboard;
