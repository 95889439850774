import React from "react";
import { Box, Container, Tab, Tabs } from "@mui/material";
import Leaderboard from "../LeaderBoardView/LeaderBoard";
import styled from "styled-components";
import bannerImage from "../images/cropped.jpg";
import { useMediaQuery, useTheme } from "@mui/system";
import TeamManagement from "../TeamManagementView/TeamManagement";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import SignIn from "../components/SignIn";
import Overview from "./Overview";
import League from "../LeagueView/League";
import CreateLeague from "../LeagueView/CreateLeague";
import JoinLeague from "../LeagueView/JoinLeague";
import { StyledHeader } from "../components/styledText";
import FFL from "../images/FFL.svg";
import Banner from "../components/Banner";

const Header = styled.header`
  position: relative;
  text-align: center;
  color: white;
`;

const HeaderImage = styled.img`
  width: 100%;
  height: auto;
  opacity: 0.8;

  @media (max-width: 768px) {
    margin-top: 50px;
  }
`;

const NavigationTabs = styled(Tabs)`
  && {
    color: white;
    position: absolute;
    bottom: 0;
    width: 100%;
  }
`;

const TabBox = styled(Box)`
  position: absolute;
  width: 100%;
  background-color: ${({ theme }) => theme.palette.primary.main};
  top: 0;
  height: 110px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 10px 0;
  @media (max-width: 768px) {
    height: 60px;
  }
`;

const CustomTab = styled(Tab)`
  && {
    color: white;
    font-size: 1rem;
    opacity: 1;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: -0.02em;

    &.Mui-selected {
      color: ${({ theme }) => theme.palette.secondary.main};
    }
    @media (max-width: 768px) {
      font-size: 0.8rem;
      padding: 8px;
    }
  }
`;

const Logo = styled.img`
  position: absolute;
  height: 100px;
  width: auto;
  top: 0px;
  left: 0px;
  background-color: ${({ theme }) => theme.palette.primary.main};
  padding: 20px;
`;

const FrontPage = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const tabPaths = ["/", "/team-management", "/leaderboard", "/leagues"];
  const labels: { [key: number]: string } = {
    0: "",
    1: "Draft your Team",
    2: "Leaderboard",
    3: "Leagues",
  };

  const selectedTab = tabPaths.indexOf(location.pathname);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    navigate(tabPaths[newValue]);
  };

  return (
    <>
      <Header>
        <HeaderImage src={bannerImage} alt="CrossFit Banner" />
        <TabBox>
          <NavigationTabs
            value={selectedTab}
            onChange={handleChange}
            centered
            theme={theme}
          >
            <CustomTab label="Overview" />
            <CustomTab label="Team" />
            <CustomTab label="Leaderboard" />
            <CustomTab label="Leagues" />
          </NavigationTabs>
        </TabBox>
        {isMobile ? null : <Logo src={FFL} onClick={() => navigate("/")} />}
      </Header>
      {selectedTab === 1 && <Banner />}

      <Container style={{ marginTop: "40px", marginBottom: "40px" }}>
        <StyledHeader variant={"h4"}>{labels[selectedTab]}</StyledHeader>
        <Routes>
          <Route path="/" element={<Overview />} />
          <Route path="/team-management" element={<TeamManagement />} />
          <Route path="/leaderboard" element={<Leaderboard />} />
          <Route path="/leagues" element={<League />} />
          <Route path="/create-league" element={<CreateLeague />} />
          <Route path="/join-league" element={<JoinLeague />} />
          <Route path="sign-in" element={<SignIn />} />
        </Routes>
      </Container>
    </>
  );
};

export default FrontPage;
