import React, { useEffect, useState } from "react";
import {
  TextField,
  List,
  ListItem,
  ListItemText,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { Affiliate } from "./useLoadAffiliates";
import ClearIcon from "@mui/icons-material/Clear";

interface AffiliateSearchProps {
  affiliates: Affiliate[];
  onSelectAffiliate: (affiliate: Affiliate | null) => void;
  selectedAffiliate: Affiliate | null;
  affiliateLabel: string;
}

const AffiliateSearch: React.FC<AffiliateSearchProps> = ({
  affiliates,
  onSelectAffiliate,
  selectedAffiliate,
  affiliateLabel,
}) => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [showList, setShowList] = useState<boolean>(false);

  useEffect(() => {
    if (selectedAffiliate) {
      setSearchTerm(selectedAffiliate.properties?.name ?? "");
    }
  }, [selectedAffiliate]);

  const filteredAffiliates = affiliates.filter((affiliate) =>
    affiliate.properties?.name.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  const handleFocus = () => {
    setShowList(true);
    setSearchTerm("");
  };

  const handleBlur = () => {
    setShowList(false);
    if (selectedAffiliate) {
      setSearchTerm(selectedAffiliate.properties?.name ?? "");
    }
  };

  const handleClearFilter = () => {
    setSearchTerm("");
    onSelectAffiliate(null);
  };

  return (
    <>
      <TextField
        label={affiliateLabel}
        variant="outlined"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        onFocus={handleFocus}
        onBlur={handleBlur}
        style={{ maxWidth: "300px" }}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="clear search"
                onClick={handleClearFilter}
                edge="end"
              >
                <ClearIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      {showList && (
        <List>
          {filteredAffiliates.slice(0, 10).map((affiliate) => (
            <ListItem
              key={affiliate.properties?.id}
              onMouseDown={() => onSelectAffiliate(affiliate)}
            >
              <ListItemText primary={affiliate.properties?.name} />
            </ListItem>
          ))}
        </List>
      )}
    </>
  );
};

export default AffiliateSearch;
