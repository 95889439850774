import { atom } from 'recoil';
import {Athlete, AthleteEventResults} from '../services/athletes';

export const athletesState = atom<{ [key: number]: Athlete[] }>({
  key: 'athletesState',
  default: {},
});

export const eventResultsState = atom<AthleteEventResults[]>({
  key: 'eventResultsState',
  default: [],
});