import { Box } from "@mui/material";
import React from "react";
import { useRecoilValue } from "recoil";
import { budgetState } from "../state/budgetState";
import styled from "styled-components";
import dollars from "../images/dollar.png";
import { StyledText } from "../components/styledText";

interface BudgetProps {
  totalSelectedPrice: number;
}

const BudgetBox = styled(Box)`
  && {
    background-color: #f8f8f2;
    width: fit-content;
    padding: 20px;
    border-radius: 5px;
    margin: 40px 0;
    border: 1px solid ${({ theme }) => theme.palette.primary.main};
    position: relative;
  }
`;

const DottedBackground = styled(Box)`
  position: absolute;
  top: 0.5rem;
  left: -0.5rem;
  width: 100%;
  height: 100%;
  --dot-bg: black;
  --dot-color: white;
  --dot-size: 1px;
  --dot-space: 3px;
  background:
    linear-gradient(90deg, #f8f8f2 2px, transparent 1%) center / 3px 3px,
    linear-gradient(#f8f8f2 2px, transparent 1%) center / 3px 3px,
    black;
  border: 1px dotted ${({ theme }) => theme.palette.primary.main};
  z-index: -1;
`;

const BudgetImage = styled.img`
  height: 50px;
  width: auto;
  margin-right: 10px;
`;

const Budget = ({ totalSelectedPrice }: BudgetProps) => {
  const budget = useRecoilValue(budgetState);

  return (
    <BudgetBox display="flex" alignItems="center">
      <DottedBackground />
      <BudgetImage src={dollars} />
      <StyledText variant="body1">
        Budget: ${budget.toFixed(0)} | Remaining: $
        {(budget - totalSelectedPrice).toFixed(0)}
      </StyledText>
    </BudgetBox>
  );
};

export default Budget;
