import React, { useState } from "react";
import { db } from "../services/firebase";
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  updateDoc,
  arrayUnion,
} from "firebase/firestore";
import { useRecoilValue } from "recoil";
import { authState } from "../state/authState";
import { TextField, Button, Typography } from "@mui/material";

const JoinLeague = () => {
  const auth = useRecoilValue(authState);
  const [inviteCode, setInviteCode] = useState("");
  const [error, setError] = useState<string | null>(null);

  const handleJoinLeague = async () => {
    if (!inviteCode) {
      setError("Invite code is required");
      return;
    }

    try {
      const leaguesRef = collection(db, "leagues");
      const q = query(leaguesRef, where("inviteCode", "==", inviteCode));
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        setError("Invalid invite code");
        return;
      }

      const leagueDoc = querySnapshot.docs[0];

      // Add user to league members
      const leagueDocRef = doc(db, "leagues", leagueDoc.id);
      await updateDoc(leagueDocRef, {
        members: arrayUnion(auth.uid),
      });

      if (!auth.uid) return;
      const userDocRef = doc(db, "users", auth.uid);
      await updateDoc(userDocRef, {
        leagues: arrayUnion(leagueDoc.id),
      });

      setInviteCode("");
      setError(null);
    } catch (error) {
      setError("Error joining league");
      console.error("Error joining league:", error);
    }
  };

  return (
    <>
      {error && <Typography color="error">{error}</Typography>}
      <div
        style={{ display: "flex", flexDirection: "column", maxWidth: "300px" }}
      >
        <TextField
          label="Invite Code"
          variant="outlined"
          margin="normal"
          value={inviteCode}
          onChange={(e) => setInviteCode(e.target.value)}
        />
        <Button variant="contained" color="primary" onClick={handleJoinLeague}>
          Join League
        </Button>
      </div>
    </>
  );
};

export default JoinLeague;
