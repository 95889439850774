import React, { useState } from "react";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  IconButton,
  Typography,
} from "@mui/material";
import styled from "styled-components";
import { Athlete } from "../services/athletes";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { StyledHeader, StyledUppercaseText } from "../components/styledText";
import athleteDescriptions from "../data/athlete-description.json";
import { useMediaQuery } from "@mui/system";
import theme from "../theme";

const StyledCard = styled(Card)`
  width: 300px;
  margin: 10px 0;

  @media (max-width: 768px) {
    margin: 10px auto;
  }
`;

const PriceLabel = styled(Box)`
  background-color: ${({ theme }) => theme.palette.secondary.main};
  font-weight: bold;
  text-align: center;
  padding: 10px;
`;

const LearnMoreButton = styled(Typography)`
  && {
    cursor: pointer;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    margin-top: 20px;

    svg {
      margin: 0 5px;
    }
  }
`;

const RookieLabel = styled(Box)`
  background-color: #075dc6;
  color: white;
  width: fit-content;
  padding: 5px;
`;

const AthleteCard: React.FC<{
  athlete: Athlete;
  price: number;
  isSelected: boolean;
  toggleAthleteSelection: (val: Athlete) => void;
  isRookie: boolean;
}> = ({ athlete, price, isSelected, toggleAthleteSelection, isRookie }) => {
  const [showMore, setShowMore] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const handleToggleMore = () => {
    setShowMore((prev) => !prev);
  };

  const athleteDescription = athleteDescriptions.find(
    (athleteDesc: any) => athleteDesc.id === athlete.id,
  )?.description;

  return (
    <StyledCard>
      {isMobile ? null : (
        <CardMedia
          component="img"
          image={athlete.profilePic}
          alt={athlete.name}
          style={{
            height: "300px",
            objectFit: "cover",
            objectPosition: "center",
          }}
        />
      )}
      <PriceLabel> Price: ${price.toFixed(0)} </PriceLabel>
      <CardContent style={{ paddingBottom: 0 }}>
        <StyledHeader gutterBottom variant="h5">
          {athlete.name}
        </StyledHeader>

        {!showMore ? (
          <LearnMoreButton onClick={handleToggleMore}>
            Read more <ArrowForwardIcon />
          </LearnMoreButton>
        ) : (
          <>
            {isRookie ? (
              <RookieLabel>
                {" "}
                <StyledUppercaseText> Rookie </StyledUppercaseText>{" "}
              </RookieLabel>
            ) : null}
            <Typography variant="body2" style={{ marginTop: "10px" }}>
              {" "}
              {athleteDescription}{" "}
            </Typography>
            <LearnMoreButton onClick={handleToggleMore}>
              <ArrowBackIcon /> Back
            </LearnMoreButton>
          </>
        )}
      </CardContent>
      <CardActions style={{ justifyContent: "flex-end", marginTop: 0 }}>
        <IconButton
          color={"primary"}
          onClick={() => toggleAthleteSelection(athlete)}
          aria-label={isSelected ? "Remove Athlete" : "Add Athlete"}
        >
          {isSelected ? <DeleteIcon /> : <AddCircleOutlineIcon />}
        </IconButton>
      </CardActions>
    </StyledCard>
  );
};

export default AthleteCard;
