import React, { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider as MuiThemeProvider } from "@mui/system";
import styled, {
  ThemeProvider as StyledThemeProvider,
} from "styled-components";
import { CssBaseline, Container } from "@mui/material";
import theme from "./theme";
import FrontPage from "./FrontPageView/FrontPage";
import { useSetRecoilState } from "recoil";
import { athletesState, eventResultsState } from "./state/atheleteState";
import { fetchAthletes } from "./services/athletes";
import { useAuthStateListener } from "./services/firebase";
import { baseScoresState, pricesState } from "./state/scoringState";
import { loadAllHistoricalData } from "./utils/loadHistoricalData";
import { calculateBaseScores } from "./utils/calculateBaseScores";
import { assignPricesToAthletes } from "./utils/getAthletePrice";

const FullWidthContainer = styled(Container)`
  && {
    padding: 0;
    max-width: 100%;
  }
`;

function App() {
  const setAthletes = useSetRecoilState(athletesState);
  const setEventResults = useSetRecoilState(eventResultsState);
  const setBaseScores = useSetRecoilState(baseScoresState);
  const setPrices = useSetRecoilState(pricesState);

  useEffect(() => {
    const initializeData = async () => {
      const menAthletes = await fetchAthletes(1);
      const womenAthletes = await fetchAthletes(2);
      const allAthletes = [...menAthletes.athletes, ...womenAthletes.athletes];

      setAthletes({
        1: menAthletes.athletes,
        2: womenAthletes.athletes,
      });

      setEventResults([
        ...menAthletes.eventResults,
        ...womenAthletes.eventResults,
      ]);

      const historicalData = await loadAllHistoricalData();
      const baseScores = calculateBaseScores(historicalData, allAthletes);
      const prices = assignPricesToAthletes(baseScores, allAthletes);

      setBaseScores(baseScores);
      setPrices(prices);
    };

    initializeData();
  }, [setBaseScores, setPrices, setAthletes, setEventResults]);

  useAuthStateListener();

  return (
    <MuiThemeProvider theme={theme}>
      <StyledThemeProvider theme={theme}>
        <CssBaseline />
        <FullWidthContainer>
          <Router>
            <FrontPage />
          </Router>
        </FullWidthContainer>
      </StyledThemeProvider>
    </MuiThemeProvider>
  );
}

export default App;
