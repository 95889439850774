import React from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { authState } from "../state/authState";
import { useEffect, useState } from "react";
import { db } from "../services/firebase";
import { collection, query, where, getDocs } from "firebase/firestore";
import {
  ListItem,
  ListItemText,
  Card,
  CardContent,
  Divider,
} from "@mui/material";
import GroupIcon from "@mui/icons-material/Group";
import { styled } from "@mui/system";
import { StyledHeader, StyledText } from "../components/styledText";
import { TeamData } from "../LeaderBoardView/LeaderBoard";
import { leaguesState } from "../state/leagueState";

const LeagueCard = styled(Card)(({ theme }) => ({
  width: "100%",
  margin: "20px 0",
  [theme.breakpoints.up("sm")]: {
    width: "80%",
  },
  [theme.breakpoints.up("md")]: {
    width: "60%",
  },
}));

const LeagueDetails = styled("div")(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

export interface League {
  id: string;
  name: string;
  inviteCode: string;
  members: string[];
}

const MyLeagues = () => {
  const auth = useRecoilValue(authState);
  const [leagues, setLeagues] = useRecoilState(leaguesState);
  const [userNames, setUserNames] = useState<{ [key: string]: string }>({});

  useEffect(() => {
    const fetchLeagues = async () => {
      if (!auth.uid) return;

      const leaguesRef = collection(db, "leagues");
      const q = query(leaguesRef, where("members", "array-contains", auth.uid));
      const querySnapshot = await getDocs(q);
      const leaguesData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      })) as League[];

      setLeagues(leaguesData);

      // Fetch user display names from teams collection
      const memberIds = Array.from(
        new Set(leaguesData.flatMap((league) => league.members)),
      );
      const teamsRef = collection(db, "teams");
      const teamsQuery = query(teamsRef, where("userId", "in", memberIds));
      const teamsSnapshot = await getDocs(teamsQuery);
      const teamsData: TeamData[] = teamsSnapshot.docs.map(
        (doc) => doc.data() as TeamData,
      );

      const userNamesMap: { [key: string]: string } = {};
      teamsData.forEach((team) => {
        userNamesMap[team.userId] = team.displayName;
      });
      setUserNames(userNamesMap);
    };

    fetchLeagues();
  }, [auth.uid, setLeagues]);

  if (leagues.length === 0) {
    return null;
  }

  return (
    <div style={{ marginTop: "20px" }}>
      <StyledHeader variant="h5">My Leagues</StyledHeader>
      {leagues.map((league) => (
        <LeagueCard key={league.id}>
          <CardContent>
            <StyledHeader variant="h6">{league.name}</StyledHeader>
            <StyledText variant="body2">
              Invite Code: {league.inviteCode}
            </StyledText>
            <Divider />
            <LeagueDetails>
              <StyledText variant="body2">Members</StyledText>
              <>
                {league.members.map((member: string, index: number) => (
                  <ListItem key={index}>
                    <GroupIcon color="primary" />
                    <ListItemText
                      primary={userNames[member] || "Loading..."}
                      style={{ marginLeft: "10px" }}
                    />
                  </ListItem>
                ))}
              </>
            </LeagueDetails>
          </CardContent>
        </LeagueCard>
      ))}
    </div>
  );
};

export default MyLeagues;
