import { AthletePerformance } from "./calculateBaseScores";
import { Athlete } from "../services/athletes";

export interface AthletePrice {
  competitorId: string;
  price: number;
  isRookie: boolean;
}

export const assignPricesToAthletes = (
  baseScores: { [key: string]: AthletePerformance },
  allAthletes: Athlete[],
): AthletePrice[] => {
  const prices: AthletePrice[] = [];
  const highestRank = Math.max(
    ...Object.values(baseScores).map((athlete) => athlete.overallRank),
  );

  const maxPrice = 300;
  const minPrice = 50;

  Object.keys(baseScores).forEach((key) => {
    let isRookie = false;
    const baseScore = baseScores[key].overallRank;
    let price =
      ((highestRank - baseScore) * (maxPrice - minPrice)) / highestRank +
      minPrice;

    if (baseScores[key].games === 0 || !baseScores[key].games) {
      price = price - 50;
      isRookie = true;
    }

    if (isNaN(price) || price <= 0) {
      price = minPrice;
    }

    prices.push({ competitorId: key, price, isRookie });
  });

  // Ensure all athletes have a price
  allAthletes.forEach((athlete) => {
    if (!prices.find((price) => price.competitorId === athlete.id)) {
      prices.push({
        competitorId: athlete.id,
        price: minPrice,
        isRookie: true,
      });
    }
  });

  return prices;
};
