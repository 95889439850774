import React, { useState } from "react";
import {
  Box,
  Card,
  Grid,
  Button,
  Alert,
  Link,
  Typography,
} from "@mui/material";
import styled from "styled-components";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import GroupIcon from "@mui/icons-material/Group";
import CreateLeague from "./CreateLeague";
import JoinLeague from "./JoinLeague";
import MyLeagues from "./MyLeagues";
import { StyledHeader, StyledText } from "../components/styledText";
import { useRecoilValue } from "recoil";
import { authState } from "../state/authState";

const ActionCard = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const CardIcon = styled.div`
  color: ${({ theme }) => theme.palette.primary.main};
  align-self: center;
  margin-right: 10px;
  margin-top: 5px;
`;

const ActionButton = styled(Button)`
  && {
    margin-top: 10px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: -0.02em;
  }
`;

const Label = styled(Box)`
  background-color: ${({ theme }) => theme.palette.secondary.main};
  font-weight: bold;
  text-align: center;
  padding: 10px;
  display: flex;
  flex-direction: row;
  height: fit-content;
  width: 100%;
`;

const ContentWrapper = styled.div`
  padding: 20px;
`;

const League = () => {
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showJoinForm, setShowJoinForm] = useState(false);
  const auth = useRecoilValue(authState);

  return (
    <>
      <Typography style={{ marginTop: "20px" }}>
        Start your own league or join someone’s league to compete with friends.
      </Typography>
      {!auth.uid && (
        <Alert severity="warning" style={{ margin: "20px 0" }}>
          You need to be logged in to create or join a league.{" "}
          <Link href="/sign-in">Sign in here</Link>.
        </Alert>
      )}
      <Box style={{ maxWidth: "800px", margin: "40px 0" }}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <ActionCard>
              <Label>
                <CardIcon>
                  <AddCircleOutlineIcon />
                </CardIcon>
                <StyledHeader variant={"h6"}> Create a League</StyledHeader>
              </Label>

              <ContentWrapper>
                <StyledText variant="body2" color="textSecondary">
                  Start your own league and invite your friends to compete.
                </StyledText>
                {showCreateForm ? (
                  <CreateLeague />
                ) : (
                  <ActionButton
                    variant="contained"
                    color="primary"
                    onClick={() => setShowCreateForm(true)}
                  >
                    Create
                  </ActionButton>
                )}
              </ContentWrapper>
            </ActionCard>
          </Grid>
          <Grid item xs={12} md={6}>
            <ActionCard>
              <Label>
                <CardIcon>
                  <GroupIcon fontSize="inherit" />
                </CardIcon>
                <StyledHeader variant={"h6"}> Join a League</StyledHeader>
              </Label>
              <ContentWrapper>
                <StyledText variant="body2" color="textSecondary">
                  Join an existing league using an invite code.
                </StyledText>
                {showJoinForm ? (
                  <JoinLeague />
                ) : (
                  <ActionButton
                    variant="contained"
                    color="primary"
                    onClick={() => setShowJoinForm(true)}
                  >
                    Join
                  </ActionButton>
                )}
              </ContentWrapper>
            </ActionCard>
          </Grid>
        </Grid>
      </Box>

      <MyLeagues />
    </>
  );
};

export default League;
