import { AthleteEventResults } from "../services/athletes";
import { AthletePerformance, BaseScores } from "./calculateBaseScores";
import { TeamData } from "../LeaderBoardView/LeaderBoard";

const calculatePerformanceScore = (
  eventResults: AthleteEventResults[],
  baseScores: { [key: string]: AthletePerformance },
) => {
  return eventResults.map((result) => {
    const baseScore =
      baseScores[result.entrant.competitorId]?.overallRank || 40;
    const eventScore =
      result.scores?.reduce(
        (total, event) => total + parseInt(event.score),
        0,
      ) ?? 0;
    const performanceMultiplier = 1 + (100 - baseScore) / 100;
    return {
      athleteId: result.entrant.competitorId,
      totalScore: eventScore * performanceMultiplier,
    };
  });
};

export const calculateFinalScoresWithPrices = (
  teams: TeamData[],
  affiliateFilter: string | null,
  eventResults: AthleteEventResults[],
  baseScores: BaseScores,
  leagueMembers: string[] | null,
) => {
  const scores = teams.map((team) => {
    const teamScore = team.athletes.reduce(
      (totalScore: number, athleteId: string) => {
        const athleteResults = eventResults.find(
          (result) => result.entrant.competitorId === athleteId,
        );

        let athleteScore = 0;
        if (athleteResults) {
          const completedEvents = athleteResults.scores?.filter(
            (score) => score.score !== "",
          );
          athleteScore =
            completedEvents && completedEvents.length > 0
              ? calculatePerformanceScore([athleteResults], baseScores)[0]
                  .totalScore
              : 0;
        }

        return totalScore + athleteScore;
      },
      0,
    );

    return {
      userId: team.userId,
      affiliate: team.affiliate,
      score: teamScore,
      displayName: team.displayName,
    };
  });

  const filteredScores = leagueMembers
    ? scores.filter((score) => leagueMembers.includes(score.userId))
    : affiliateFilter
      ? scores.filter(
          (score) => score.affiliate?.properties?.name === affiliateFilter,
        )
      : scores;

  return filteredScores.sort((a, b) => b.score - a.score);
};
