import { Typography } from "@mui/material";
import { styled } from "@mui/system";

export const StyledHeader = styled(Typography)`
  && {
    font-family: "Roboto", sans-serif;
    font-weight: 510;
    text-transform: uppercase;
    letter-spacing: -0.04em;
  }
`;

export const StyledUppercaseText = styled(Typography)`
  && {
    font-family: "Roboto Mono", monospace;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: -0.02em;
  }
`;

export const StyledText = styled(Typography)`
  && {
    font-family: "Roboto Mono", monospace;
    font-weight: 500;
    letter-spacing: -0.02em;
  }
`;
