import React, { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { authState } from "../state/authState";
import {
  collection,
  addDoc,
  getDocs,
  query,
  where,
  updateDoc,
  doc,
} from "firebase/firestore";
import { db } from "../services/firebase";
import { Athlete } from "../services/athletes";
import { Grid, CircularProgress, Tabs, Tab, Alert, Link } from "@mui/material";
import { useNavigate } from "react-router-dom";
import AthleteCard from "./AthleteCard";
import { athletesState } from "../state/atheleteState";
import MyTeam from "./MyTeam";
import { pricesState } from "../state/scoringState";
import { budgetState } from "../state/budgetState";
import Budget from "./Budget";
import {
  Affiliate,
  useLoadAffiliates,
} from "../components/affiliates/useLoadAffiliates";
import Success from "../components/Success";
import { teamsState } from "../state/teamState";
import { StyledHeader } from "../components/styledText";

export const LOCK_DATE = new Date("2024-08-09T19:00:00Z");
const MAX_TEAM_SIZE = 6;

const TeamManagement: React.FC = () => {
  const athletes = useRecoilValue(athletesState);
  const prices = useRecoilValue(pricesState);
  const budget = useRecoilValue(budgetState);
  const [, setTeams] = useRecoilState(teamsState);
  const affiliates = useLoadAffiliates();

  const [selectedAthletes, setSelectedAthletes] = useState<Athlete[]>([]);
  const [displayName, setDisplayName] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(true);
  const [division, setDivision] = useState<number>(1); // 1 for men, 2 for women
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [teamDocId, setTeamDocId] = useState<string | null>(null);
  const [selectedAffiliate, setSelectedAffiliate] = useState<Affiliate | null>(
    null,
  );
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const auth = useRecoilValue(authState);
  const navigate = useNavigate();

  const handleSelectAffiliate = (affiliate: Affiliate | null) => {
    setSelectedAffiliate(affiliate);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const calculateTotalPrice = (selectedAthletes: Athlete[]) => {
    return selectedAthletes.reduce((total, athlete) => {
      const athletePrice =
        prices.find((price) => price.competitorId === athlete.id)?.price || 0;
      return total + athletePrice;
    }, 0);
  };

  const selectAthleteValidation = (athlete: Athlete) => {
    const totalSelectedPrice = calculateTotalPrice([
      ...selectedAthletes,
      athlete,
    ]);
    if (new Date() >= LOCK_DATE) {
      setErrorMessage(
        "Teams are locked. You cannot change your team after the competition starts.",
      );
      return false;
    }
    if (
      totalSelectedPrice > budget &&
      !selectedAthletes.find((a) => a.id === athlete.id)
    ) {
      setErrorMessage(`You can't exceed the budget of $${budget}.`);
      return false;
    }
    if (
      selectedAthletes.length >= MAX_TEAM_SIZE &&
      !selectedAthletes.find((a) => a.id === athlete.id)
    ) {
      setErrorMessage(`You can only select up to ${MAX_TEAM_SIZE} athletes.`);
      return false;
    } else {
      setErrorMessage(null);
      return true;
    }
  };

  useEffect(() => {
    const loadUserTeam = async () => {
      setLoading(true);
      if (auth.uid) {
        const q = query(
          collection(db, "teams"),
          where("userId", "==", auth.uid),
        );
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
          const teamData = querySnapshot.docs[0].data();
          setTeamDocId(querySnapshot.docs[0].id);
          const teamAthletes = teamData.athletes
            .map((athleteId: string) => {
              return (
                athletes[1]?.find((a) => a.id === athleteId) ||
                athletes[2]?.find((a) => a.id === athleteId)
              );
            })
            .filter(
              (athlete: Athlete | undefined): athlete is Athlete => !!athlete,
            );
          setSelectedAthletes(teamAthletes);
          setSelectedAffiliate(teamData.affiliate || null);
          setDisplayName(teamData.displayName || "");
        }
      }
      setLoading(false);
    };

    if (Object.keys(athletes).length) {
      loadUserTeam();
    }
  }, [auth.uid, athletes]);

  const toggleAthleteSelection = (athlete: Athlete) => {
    const alreadySelected = selectedAthletes.find((a) => a.id === athlete.id);
    if (alreadySelected || selectAthleteValidation(athlete)) {
      setSelectedAthletes((prevSelectedAthletes) =>
        alreadySelected
          ? prevSelectedAthletes.filter((a) => a.id !== athlete.id)
          : [...prevSelectedAthletes, athlete],
      );
    }
  };

  const saveTeam = async () => {
    if (new Date() >= LOCK_DATE) {
      setErrorMessage(
        "Teams are locked. You cannot change your team after the competition starts.",
      );
      return;
    }
    if (selectedAthletes.length !== MAX_TEAM_SIZE) {
      setErrorMessage(`You must select exactly ${MAX_TEAM_SIZE} athletes.`);
      return;
    }
    if (auth.uid) {
      try {
        const userTeamsRef = collection(db, "teams");
        if (teamDocId) {
          const teamDocRef = doc(db, "teams", teamDocId);
          await updateDoc(teamDocRef, {
            athletes: selectedAthletes.map((a) => a.id),
            affiliate: selectedAffiliate,
            displayName,
          });
        } else {
          const newDocRef = await addDoc(userTeamsRef, {
            userId: auth.uid,
            athletes: selectedAthletes.map((a) => a.id),
            affiliate: selectedAffiliate,
            displayName,
          });
          setTeamDocId(newDocRef.id);
        }
        setSnackbarOpen(true);
        // Update the teams state
        setTeams((prevTeams) =>
          prevTeams.map((team) =>
            team.userId === auth.uid
              ? {
                  ...team,
                  athletes: selectedAthletes.map((a) => a.id),
                  affiliate: selectedAffiliate,
                  displayName,
                }
              : team,
          ),
        );
      } catch (error) {
        console.error("Error saving team:", error);
      }
    } else {
      alert("You need to be logged in to save your team.");
      navigate("/sign-in");
    }
  };

  const handleDivisionChange = (
    event: React.SyntheticEvent,
    newValue: number,
  ) => {
    setDivision(newValue);
  };

  const handleDisplayNameChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setDisplayName(event.target.value);
  };

  const totalSelectedPrice = calculateTotalPrice(selectedAthletes);

  return (
    <>
      {!auth.uid && (
        <Alert severity="warning" style={{ margin: "20px 0" }}>
          You need to be logged in to save your team.{" "}
          <Link href="/sign-in">Sign in here</Link>.
        </Alert>
      )}
      <Budget totalSelectedPrice={totalSelectedPrice} />
      <MyTeam
        selectedAthletes={selectedAthletes}
        selectedAffiliate={selectedAffiliate}
        displayName={displayName}
        authUid={auth.uid}
        onToggleAthleteSelection={toggleAthleteSelection}
        onSaveTeam={saveTeam}
        onAffiliateChange={handleSelectAffiliate}
        onDisplayNameChange={handleDisplayNameChange}
        prices={prices}
        affiliates={affiliates}
        errorMessage={errorMessage}
      />
      <Success
        message={"Successfully saved team"}
        snackbarOpen={snackbarOpen}
        handleSnackbarClose={handleSnackbarClose}
      />
      <StyledHeader variant={"h5"} style={{ marginTop: "40px" }}>
        {" "}
        Athletes{" "}
      </StyledHeader>
      <Tabs value={division} onChange={handleDivisionChange} centered>
        <Tab label="Men" value={1} />
        <Tab label="Women" value={2} />
      </Tabs>
      {loading ? (
        <CircularProgress />
      ) : (
        <Grid container spacing={3}>
          {athletes[division]
            ?.slice()
            .sort((a, b) => {
              const priceA =
                prices.find((price) => price.competitorId === a.id)?.price || 0;
              const priceB =
                prices.find((price) => price.competitorId === b.id)?.price || 0;
              return priceB - priceA; // Sort in descending order by price
            })
            .map((athlete) => {
              const athletePrice = prices.find(
                (price) => price.competitorId === athlete.id,
              );
              return (
                <Grid item xs={12} sm={6} md={4} key={athlete.id}>
                  <AthleteCard
                    athlete={athlete}
                    price={athletePrice?.price || 50}
                    isSelected={
                      !!selectedAthletes.find((a) => a.id === athlete.id)
                    }
                    toggleAthleteSelection={toggleAthleteSelection}
                    isRookie={athletePrice?.isRookie || false}
                  />
                </Grid>
              );
            })}
        </Grid>
      )}
    </>
  );
};

export default TeamManagement;
