import { Card, CardContent, Grid, Typography } from "@mui/material";
import styled from "styled-components";
import { useTheme } from "@mui/system";
import image1 from "../images/image1.jpg";
import image3 from "../images/image3.jpg";
import budget from "../images/budget.jpg";
import team from "../images/team.jpg";
import team2 from "../images/team2.jpg";
import athlete from "../images/athlete.jpg";
import { StyledHeader, StyledUppercaseText } from "../components/styledText";
import React from "react";

const StepCard = styled(Card)`
  display: flex;
  flex-direction: column;
  height: 550px;

  @media (max-width: 768px) {
    justify-content: space-between;
    height: auto;
  }
`;

const StepImage = styled.img`
  width: 100%;
  height: 300px; /* Set a fixed height for consistency */
  object-fit: cover; /* Maintain aspect ratio and cover the container */
  object-position: center;
`;

const StepLabel = styled(StyledUppercaseText)`
  background-color: ${({ theme }) => theme.palette.secondary.main};
  font-weight: bold;
  text-align: center;
  padding: 10px;
`;

const OverviewPage = () => {
  const theme = useTheme();

  return (
    <div style={{ marginTop: "40px" }}>
      <StyledHeader style={{ marginBottom: "20px" }} variant="h4">
        How it works
      </StyledHeader>
      <Grid container spacing={4}>
        <Grid item xs={12} md={4}>
          <StepCard>
            <StepImage src={image1} alt="Create or Join a League" />
            <StepLabel theme={theme}>STEP 01</StepLabel>
            <CardContent>
              <StyledHeader variant="h5">Create or Join a League</StyledHeader>
              <Typography>
                Start your own league or join your affiliate’s league to compete
                with friends.
              </Typography>
            </CardContent>
          </StepCard>
        </Grid>
        <Grid item xs={12} md={4}>
          <StepCard>
            <StepImage src={team} alt="Draft Your Team" />
            <StepLabel theme={theme}>STEP 02</StepLabel>
            <CardContent>
              <StyledHeader variant="h5">Draft Your Team</StyledHeader>
              <Typography variant="body1">
                Use your budget to draft a team of top CrossFit athletes.
                Balance your spending and strategize your picks.
              </Typography>
            </CardContent>
          </StepCard>
        </Grid>
        <Grid item xs={12} md={4}>
          <StepCard>
            <StepImage src={image3} alt="Track Performance" />
            <StepLabel theme={theme}>STEP 03</StepLabel>
            <CardContent>
              <StyledHeader variant="h5">Track Performance</StyledHeader>
              <Typography>
                Follow the performance of your athletes in competitions. The
                better they do, the more points you earn.
              </Typography>
            </CardContent>
          </StepCard>
        </Grid>
      </Grid>

      <StyledHeader
        variant="h4"
        style={{ marginTop: "40px", marginBottom: "20px" }}
      >
        Strategies
      </StyledHeader>
      <Grid container spacing={4}>
        <Grid item xs={12} md={4}>
          <StepCard>
            <StepImage src={budget} alt="Budget Management" />
            <StepLabel theme={theme}>TIP 01</StepLabel>
            <CardContent>
              <StyledHeader variant="h5">Budget Management</StyledHeader>
              <Typography variant="body1">
                Spend wisely. Invest in top-tier athletes or find hidden gems to
                maximize your points.
              </Typography>
            </CardContent>
          </StepCard>
        </Grid>
        <Grid item xs={12} md={4}>
          <StepCard>
            <StepImage src={athlete} alt="Athlete Selection" />
            <StepLabel theme={theme}>TIP 02</StepLabel>
            <CardContent>
              <StyledHeader variant="h5">Athlete Selection</StyledHeader>
              <Typography variant="body1">
                Consider past performances, athlete stats, and event types to
                make informed picks.
              </Typography>
            </CardContent>
          </StepCard>
        </Grid>

        <Grid item xs={12} md={4}>
          <StepCard>
            <StepImage src={team2} alt="Team Consistency" />
            <StepLabel theme={theme}>TIP 03</StepLabel>
            <CardContent>
              <StyledHeader variant="h5">Sign Up Early</StyledHeader>
              <Typography variant="body1">
                Sign up and create your team before the Games start. Teams cannot be changed after Games start.
              </Typography>
            </CardContent>
          </StepCard>
        </Grid>
      </Grid>
    </div>
  );
};

export default OverviewPage;
