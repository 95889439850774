import men2020 from "../historicalData/2020/men.json";
import women2020 from "../historicalData/2020/women.json";
import men2021 from "../historicalData/2021/men.json";
import women2021 from "../historicalData/2021/women.json";
import men2022 from "../historicalData/2022/men.json";
import women2022 from "../historicalData/2022/women.json";
import men2023 from "../historicalData/2023/men.json";
import women2023 from "../historicalData/2023/women.json";
import menQuarters1 from "../historicalData/quarters/quarters2024-men.json";
import womenQuarters1 from "../historicalData/quarters/quarters2024-women.json";
import menQuarters2 from "../historicalData/quarters/quarters2024-men-p2.json";
import womenQuarters2 from "../historicalData/quarters/quarters2024-women-p2.json";

export const loadAllHistoricalData = async () => {
  const historicalData = [
    { year: 2020, division: "men", data: men2020 },
    { year: 2020, division: "women", data: women2020 },
    { year: 2021, division: "men", data: men2021 },
    { year: 2021, division: "women", data: women2021 },
    { year: 2022, division: "men", data: men2022 },
    { year: 2022, division: "women", data: women2022 },
    { year: 2023, division: "men", data: men2023 },
    { year: 2023, division: "women", data: women2023 },
    { year: 2024, division: "men", data: menQuarters1 },
    { year: 2024, division: "women", data: womenQuarters1 },
    { year: 2024, division: "men", data: menQuarters2 },
    { year: 2024, division: "women", data: womenQuarters2 },
  ];

  return historicalData;
};
