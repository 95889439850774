import React from "react";
import styled, { keyframes } from "styled-components";
import { Typography } from "@mui/material";

const moveText = keyframes`
    0% {
        transform: translateX(100vh);
    }
    100% {
        transform: translateX(-100vh);
    }
`;

const moveTextMobile = keyframes`
    0% {
        transform: translateX(100%);
    }
    100% {
        transform: translateX(-100%);
    }
`;

const BannerContainer = styled.div`
  width: 100%;
  white-space: nowrap;
  background-color: ${({ theme }) => theme.palette.secondary.main};
  color: ${({ theme }) => theme.palette.primary.main};
  position: relative;
  margin-top: -1em;
`;

const BannerText = styled(Typography)`
  position: relative;
  animation: ${moveText} 30s linear infinite;
  margin: 0;
  padding: 10px;

  font-family: "Roboto", sans-serif;
  font-weight: 520;
  text-transform: uppercase;
  letter-spacing: -0.04em;

  & span {
    display: inline-block;
    margin-right: 20px;
  }

  @media (max-width: 768px) {
    animation: ${moveTextMobile} 20s linear infinite;
  }
`;

const Banner: React.FC = () => {
  return (
    <BannerContainer>
      <BannerText>
        <span>
          {" "}
          Lock your team before <strong>Aug 7th</strong>{" "}
        </span>
      </BannerText>
    </BannerContainer>
  );
};

export default Banner;
