import axios from "axios";
import { useTestData } from "../config";
import menFinished2023 from "../historicalData/2023/men.json";
import womenFinished2023 from "../historicalData/2023/women.json";

export interface Athlete {
  id: string;
  name: string;
  height: string;
  weight: string;
  overallRank: string;
  profilePic: string;
  divisionId: number;
}

export interface EventResult {
  ordinal: number;
  rank: string;
  score: string;
  valid: string;
  scoreDisplay: string;
  scoreIdentifier: string | null;
  mobileScoreDisplay: string;
  scaled: string;
  video: string;
  heat: string;
  lane: string;
  breakdown?: string;
}

export interface AthleteEventResults {
  overallRank: string;
  overallScore: string;
  entrant: {
    competitorId: string;
    competitorName: string;
    affiliateName: string;
  };
  scores?: EventResult[];
}

export const fetchAthletes = async (
  division: number = 1,
): Promise<{ athletes: Athlete[]; eventResults: AthleteEventResults[] }> => {
  const cacheKey = `athletes_division_${division}`;
  const cachedData = localStorage.getItem(cacheKey);
  let athletes;

  if (useTestData) {
    const testData =
      division === 1
        ? menFinished2023.leaderboardRows
        : womenFinished2023.leaderboardRows;
    const athletes = testData.map((row: any) => ({
      id: row.entrant.competitorId,
      name: row.entrant.competitorName,
      height: row.entrant.height,
      weight: row.entrant.weight,
      overallRank: row.overallRank,
      profilePic: `https://profilepicsbucket.crossfit.com/${row.entrant.profilePicS3key}`,
      divisionId: division,
    }));
    return { athletes, eventResults: testData };
  } else {
    if (cachedData) {
      athletes = JSON.parse(cachedData).athletes;
    } else {
      try {
        const response = await axios.get(
          `https://proxyleaderboard-w62elb73zq-uc.a.run.app`,
          {
            params: { division },
          },
        );
        athletes = response.data.leaderboardRows.map((row: any) => ({
          id: row.entrant.competitorId,
          name: row.entrant.competitorName,
          height: row.entrant.height,
          weight: row.entrant.weight,
          overallRank: row.overallRank,
          profilePic: `https://profilepicsbucket.crossfit.com/${row.entrant.profilePicS3key}`,
          divisionId: division,
        }));

        // Cache the athlete data
        localStorage.setItem(cacheKey, JSON.stringify({ athletes }));
      } catch (error) {
        console.error("Error fetching athletes:", error);
        throw error;
      }
    }

    // Always fetch fresh event results
    try {
      const response = await axios.get(
        `https://proxyleaderboard-w62elb73zq-uc.a.run.app`,
        {
          params: { division },
        },
      );
      const eventResults = response.data.leaderboardRows;
      return { athletes, eventResults };
    } catch (error) {
      console.error("Error fetching event results:", error);
      throw error;
    }
  }
};
