import React, { useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { authState } from '../state/authState';
import { getAuth, GoogleAuthProvider, signInWithCredential, signInWithPopup, createUserWithEmailAndPassword, signInWithEmailAndPassword, Auth } from 'firebase/auth';
import {Typography, TextField, Button, Alert, Container} from '@mui/material';
import styled from 'styled-components';
import GoogleIcon from '@mui/icons-material/Google';
import { useNavigate } from "react-router-dom";

const GoogleSignInButton = styled(Button)`
    && {
        margin: 40px 0;
        padding: 10px 20px;
        border-radius: 5px;
        background-color: #301934;
        color: white;
        display: flex;
        align-items: center;
        cursor: pointer;
        transition: background-color 0.3s ease;

        &:hover {
            background-color: #357ae8;
        }

        svg {
            margin-right: 10px;
        }
    }
`;

const FormContainer = styled.div`
    margin-top: 20px;
`;

export async function signInWithGoogle() {
  const provider = new GoogleAuthProvider();
  const authFirebase = getAuth();
  let uid = null;

  try {
    const result = await signInWithPopup(authFirebase, provider);
    const credential = GoogleAuthProvider.credentialFromResult(result);

    if (credential) {
      const usercred = await signInWithCredential(authFirebase, credential);
      uid = usercred.user.uid;
    }
  } catch (error) {
    console.error(error);
  }

  return uid;
}

export async function signUpWithEmail(auth: Auth, email: string, password: string) {
  try {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    return userCredential.user.uid;
  } catch (error) {
    throw error;
  }
}

export async function signInWithEmail(auth: Auth, email: string, password: string) {
  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    return userCredential.user.uid;
  } catch (error) {
    throw error;
  }
}

const SignIn = () => {
  const setAuth = useSetRecoilState(authState);
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [isSignUp, setIsSignUp] = useState(false);
  const authFirebase = getAuth();

  const handleSignInWithGoogle = async () => {
    const uid = await signInWithGoogle();
    if (uid) {
      setAuth({ uid });
      navigate('/team-management'); // Redirect to Team Management page
    }
  };

  const handleEmailAuth = async () => {
    try {
      let uid;
      if (isSignUp) {
        uid = await signUpWithEmail(authFirebase, email, password);
      } else {
        uid = await signInWithEmail(authFirebase, email, password);
      }
      setAuth({ uid });
      navigate('/team-management'); // Redirect to Team Management page
    } catch (error: unknown) {
      if (error instanceof Error) {
        switch (error.message) {
          case 'Firebase: Error (auth/invalid-email).':
            setError('Invalid email address.');
            break;
          case 'Firebase: Error (auth/user-not-found).':
            setError('User not found.');
            break;
          case 'Firebase: Error (auth/wrong-password).':
            setError('Incorrect password.');
            break;
          case 'Firebase: Error (auth/email-already-in-use).':
            setError('Email is already in use.');
            break;
          default:
            setError('An unknown error occurred.');
        }
      } else {
        setError('Unknown error occurred during authentication.');
      }
    }
  };

  return (
    <Container style={{marginTop: '40px', maxWidth: '600px'}}>
      <Typography variant="h4">Sign In</Typography>
      <GoogleSignInButton onClick={handleSignInWithGoogle}>
        <GoogleIcon />
        Sign in with Google
      </GoogleSignInButton>
      <FormContainer>
        <Typography variant="h6">Or sign in with email</Typography>
        <TextField
          label="Email"
          variant="outlined"
          fullWidth
          margin="normal"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          label="Password"
          type="password"
          variant="outlined"
          fullWidth
          margin="normal"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        {error && <Alert severity="error">{error}</Alert>}
        <Button variant="contained" onClick={handleEmailAuth} fullWidth style={{ marginTop: '10px' }}>
          {isSignUp ? 'Sign Up with Email' : 'Sign In with Email'}
        </Button>
        <Button
          variant="text"
          fullWidth
          style={{ marginTop: '10px' }}
          onClick={() => setIsSignUp(!isSignUp)}
        >
          {isSignUp ? 'Already have an account? Sign In' : 'Don\'t have an account? Sign Up'}
        </Button>
      </FormContainer>
    </Container>
  );
};

export default SignIn;


