import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import {createUserWithEmailAndPassword, getAuth, onAuthStateChanged, signInWithEmailAndPassword} from "firebase/auth";
import {useSetRecoilState} from "recoil";
import {authState} from "../state/authState";
import {useEffect} from "react";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyA_hEoc6jmW3wTg1IWIGwtdHcWQexg0yaA",
  authDomain: "crossfit-fantasy.firebaseapp.com",
  projectId: "crossfit-fantasy",
  storageBucket: "crossfit-fantasy.appspot.com",
  messagingSenderId: "1045381178940",
  appId: "1:1045381178940:web:3968a7b8a9a6632562c539"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);


export const useAuthStateListener = () => {
  const setAuth = useSetRecoilState(authState);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        setAuth({ uid: user.uid });
      } else {
        setAuth({ uid: null });
      }
    });

    return () => unsubscribe();
  }, [setAuth]);
};

export const signUpWithEmail = async (email: string, password: string) => {
  try {
    const userCredential = await createUserWithEmailAndPassword(auth, email, password);
    return userCredential.user;
  } catch (error) {
    console.error("Error signing up:", error);
    throw error;
  }
};

// Sign in function
export const signInWithEmail = async (email: string, password: string) => {
  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    return userCredential.user;
  } catch (error) {
    console.error("Error signing in:", error);
    throw error;
  }
};