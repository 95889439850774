import {atom} from 'recoil';
import {AthletePrice} from "../utils/getAthletePrice";
import {BaseScores} from "../utils/calculateBaseScores";

export const baseScoresState = atom<BaseScores>({
  key: 'baseScoresState',
  default: {},
});

export const pricesState = atom<AthletePrice[]>({
  key: 'pricesState',
  default: [],
});
