import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#181818", // Primary color
    },
    secondary: {
      main: "#E0FD53", // Secondary color
    },
    text: {
      primary: "#181818",
      secondary: "#181818",
    },
    background: {
      default: "#F8F8F2", // Background color
    },
    common: {
      white: "#FFFFFF",
    },
    divider: "#181818",
  },
  typography: {
    fontFamily: "Roboto, PFDIN, sans-serif",
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 768,
      lg: 1200,
      xl: 1536,
    },
  },
});

export default theme;
