import { Athlete } from "../services/athletes";

export interface AthletePerformance {
  competitorId: string;
  competitorName: string;
  overallRank: number;
  previousRanks: number[];
  games: number;
}

export interface BaseScores {
  [key: string]: AthletePerformance;
}

export const calculateBaseScores = (
  historicalData: any[],
  allAthletes: Athlete[],
): BaseScores => {
  const baseScores: BaseScores = {};

  historicalData.forEach(({ data }) => {
    data.leaderboardRows.forEach((row: any) => {
      const { competitorId, competitorName } = row.entrant;
      const overallRank = parseInt(row.overallRank);

      if (!baseScores[competitorId]) {
        baseScores[competitorId] = {
          competitorId,
          competitorName,
          overallRank: 0,
          previousRanks: [],
          games: 0,
        };
      }

      baseScores[competitorId].overallRank += overallRank;
      baseScores[competitorId].previousRanks.push(overallRank);

      if (data.competition.competitionType === "games") {
        baseScores[competitorId].games += 1;
      }
    });
  });

  Object.keys(baseScores).forEach((key) => {
    baseScores[key].overallRank =
      baseScores[key].overallRank / baseScores[key].previousRanks.length;
  });

  // Add athletes not in historical data with default values
  allAthletes.forEach((athlete) => {
    if (!baseScores[athlete.id]) {
      baseScores[athlete.id] = {
        competitorId: athlete.id,
        competitorName: athlete.name,
        overallRank: 100,
        previousRanks: [100],
        games: 0,
      };
    }
  });

  return baseScores;
};
