import { useState, useEffect } from "react";
import affiliatesData from "../../data/affiliates.json";

export interface Affiliate {
  type: string;
  properties?: {
    name: string;
    street: string;
    id: number;
    slug: string;
    city: string | null;
    state: string | null;
    country: string;
    images: {
      primary: { url: string; medium_url?: string | null };
      logo?: { url: string | null };
      map?: { url: string | null };
    };
  };
  geometry?: {
    type: string;
    coordinates: number[];
  };
}

export const useLoadAffiliates = (): Affiliate[] => {
  const [affiliates, setAffiliates] = useState<Affiliate[]>([]);

  useEffect(() => {
    // Load affiliates data
    // @ts-ignore
    setAffiliates(affiliatesData.features);
  }, []);

  return affiliates;
};
