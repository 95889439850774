import { atom } from 'recoil';

interface AuthState {
  uid: string | null;
}

export const authState = atom<AuthState>({
  key: 'authState',
  default: {
    uid: null,
  },
});